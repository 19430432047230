import React, { useRef } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { Grid, Box, useMediaQuery } from '@material-ui/core'
import styled from 'styled-components'

import Layout from '../components/Layout'
import Section from '../components/Section'
import Visible from '../components/Visible'
import OptImages from '../components/OptImages'
import { Fade } from '../components/Transitions'
import Hero from '../components/HeroAmbassadors'
// import SignupSection from '../components/SignupSection'
import ImageGridSection from '../components/ImageGridSection'

import CheckSvg from '../../resources/images/svg/inline/check-circle.svg'
import IconBrandieSvg from '../../resources/images/svg/inline/icon_brandie.svg'
import AmbassadorsPageQuery from '../queries/AmbassadorsPageQuery'
import ContactSection from '../components/ContactSection'

const CheckList = styled.div`
  display: flex;
  align-items: flext-end;
  justify-content: flext-start;
  text-align: left;

  svg {
    margin-right: 10px;
    margin-top: -2px;
    min-width: 30px;
    min-height: 30px;
  }
`

const ResponsiveTitle = styled.div`
  h1 {
    margin: 0;
    font-size: 90px;

    @media (min-width: 600px) {
      font-size: 145px;
    }
  }
`

export default () => {
  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'))
  const xlUp = useMediaQuery(theme.breakpoints.up('xl'))

  const contactSectionRef = useRef(null)
  const scrollToRef = (ref) =>
    window.scrollTo({ left: 0, top: ref.current.offsetTop, behavior: 'smooth' })

  return (
    <AmbassadorsPageQuery
      render={(content) => (
        <Layout>
          <Hero
            ecenter1={
              <h2>Be discovered by your favorite brands & earn rewards!</h2>
            }
            link='Request Demo'
            onBtnClickHandler={(e) => scrollToRef(contactSectionRef)}
          />
          <Section
            position='relative'
            overflow='hidden'
            color='white'
            gradient={[ '#E0B148', '#DDA21B' ]}
            centered
            svg={IconBrandieSvg}
            svgProps={{ viewBox: '0 0 152.34 259.7' }}
            textAlign='center'
          >
            <Box my={10} mx={4}>
              <Grid
                container
                spacing={5}
                justify='center'
                alignItems='center'
                direction='column'
              >
                <Grid item xs={12} sm={6}>
                  <div
                    style={{
                      height: '140px',
                      width: '140px',
                      background: 'white',
                      borderRadius: '100px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <OptImages.EmojiLike style={{ width: '70px' }} />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Visible
                    render={(isVisible, ref) => (
                      <Fade in={isVisible}>
                        <h2 ref={ref}>Why become a member?</h2>
                      </Fade>
                    )}
                  />
                </Grid>
                <Grid container item xs={12} sm={6} spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <CheckList>
                      <CheckSvg />
                      Earn exclusive invites and merchandise.
                    </CheckList>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CheckList>
                      <CheckSvg />
                      Get recognized and highlighted by top brands.
                    </CheckList>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CheckList>
                      <CheckSvg />
                      Grow your social media account organically.
                    </CheckList>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CheckList>
                      <CheckSvg />
                      Become a loyal ambassador and get rewarded.
                    </CheckList>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Section>
          <Section centered bgcolor='#ffffff'>
            <Grid
              container
              spacing={0}
              justify='center'
              alignItems='center'
              direction='row'
            >
              <Grid item xs={12} sm={6}>
                <Box color='text.primary' m={xlUp ? 15 : smUp ? 10 : 5}>
                  <Visible
                    render={(isVisible, ref) => (
                      <Fade in={isVisible}>
                        <h2 ref={ref}>We value your voice. Anyone can join!</h2>
                      </Fade>
                    )}
                  />
                  <Visible
                    render={(isVisible, ref) => (
                      <React.Fragment>
                        <Fade in={isVisible}>
                          <p ref={ref}>
                            It doesn’t matter how many followers you have,
                            because on Brandie every voice has a value and even
                            ambassadors with small communities can benefit.
                          </p>
                        </Fade>
                        <Fade in={isVisible}>
                          <p>
                            Regardless if you’re a private social media user,
                            aspiring influencer, or a celebrity - now you can
                            earn rewards and engage with your favorite brands
                            all in one place!
                          </p>
                        </Fade>
                      </React.Fragment>
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box mb={-4} mt={0}>
                  <OptImages.IphoneBrandie
                    style={{ height: '100%', transform: 'scale(1.15)' }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Section>

          <Section centered textured gradient={[ '#BF72AB', '#AC5796' ]}>
            <Grid
              container
              spacing={0}
              justify='center'
              alignItems='center'
              direction='row-reverse'
            >
              <Grid item xs={12} sm={6}>
                <Box color='white' m={xlUp ? 15 : smUp ? 10 : 5}>
                  <Visible
                    render={(isVisible, ref) => (
                      <Fade in={isVisible}>
                        <h2 ref={ref}>For content creators</h2>
                      </Fade>
                    )}
                  />
                  <Visible
                    render={(isVisible, ref) => (
                      <React.Fragment>
                        <Fade in={isVisible}>
                          <p ref={ref}>
                            Do you like sharing pictures at your favorite
                            restaurants, when traveling, or when engaging with
                            brands in your everyday life? If you love snapping
                            photos and sharing them on social media, Brandie is
                            the platform for you.
                          </p>
                        </Fade>
                        <Fade in={isVisible}>
                          <p>
                            Your favorite brands value your effort, now it’s
                            time for you to get rewarded!
                          </p>
                        </Fade>
                      </React.Fragment>
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box mb={-4} mt={0}>
                  <OptImages.IphoneContent
                    style={{ height: '100%', transform: 'scale(1.15)' }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Section>

          <Section centered bgcolor='#ffffff'>
            <Grid
              container
              spacing={0}
              justify='center'
              alignItems='center'
              direction='row'
            >
              <Grid item xs={12} sm={6}>
                <Box color='text.primary' m={xlUp ? 15 : smUp ? 10 : 5}>
                  <Visible
                    render={(isVisible, ref) => (
                      <Fade in={isVisible}>
                        <h2 ref={ref}>Earn awesome rewards!</h2>
                      </Fade>
                    )}
                  />
                  <Visible
                    render={(isVisible, ref) => (
                      <Fade in={isVisible}>
                        <p ref={ref}>
                          Ensure that every photo you click is worth a lot more
                          than just likes and comments! Your content will always
                          stand a chance to be featured by your favorite brands!
                          Become an ambassador, earn awesome rewards, get
                          recognized, and do much more with your social media!
                          You can even save up points and redeem some exclusive
                          merchandise from the in-app store!
                        </p>
                      </Fade>
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box mb={-4} mt={0}>
                  <OptImages.IphoneReward
                    style={{ height: '100%', transform: 'scale(1.10)' }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Section>

          <Section
            position='relative'
            overflow='hidden'
            color='white'
            gradient={[ '#5AB7B1', '#4CAAA4' ]}
            centered
            svg={IconBrandieSvg}
            svgProps={{ viewBox: '0 0 152.34 259.7' }}
            textAlign='center'
            py={10}
          >
            <Grid
              container
              spacing={5}
              justify='center'
              alignItems='center'
              direction='column'
            >
              <Grid item xs={12} sm={6}>
                <Visible
                  render={(isVisible, ref) => (
                    <Fade in={isVisible}>
                      <h2 ref={ref}>How it works</h2>
                    </Fade>
                  )}
                />
              </Grid>
              <Grid container item xs={12} sm={10} spacing={6}>
                <Visible
                  render={(isVisible, ref) => (
                    <Fade in={isVisible} timeout={0}>
                      <Grid item xs={12} sm={4}>
                        <ResponsiveTitle>
                          <h1 ref={ref}>1</h1>
                        </ResponsiveTitle>
                        <p style={{ fontSize: '2.3rem', marginTop: '0' }}>
                          Shoot Photos
                        </p>
                      </Grid>
                    </Fade>
                  )}
                />
                <Visible
                  render={(isVisible, ref) => (
                    <Fade in={isVisible} timeout={700}>
                      <Grid item xs={12} sm={4}>
                        <ResponsiveTitle>
                          <h1 ref={ref}>2</h1>
                        </ResponsiveTitle>
                        <p style={{ fontSize: '2.3rem', marginTop: '0' }}>
                          Share to your followers
                        </p>
                      </Grid>
                    </Fade>
                  )}
                />
                <Visible
                  render={(isVisible, ref) => (
                    <Fade in={isVisible} timeout={1400}>
                      <Grid item xs={12} sm={4}>
                        <ResponsiveTitle>
                          <h1 ref={ref}>3</h1>
                        </ResponsiveTitle>
                        <p style={{ fontSize: '2.3rem', marginTop: '0' }}>
                          Earn Rewards
                        </p>
                      </Grid>
                    </Fade>
                  )}
                />
              </Grid>
            </Grid>
          </Section>

          <ImageGridSection />

          {/* <SignupSection /> */}
          <ContactSection ref={contactSectionRef} />
        </Layout>
      )}
    />
  )
}
