import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
// import Link from '@input-output-hk/front-end-core-components/components/Link'

import ImagesQuery from '../queries/ImagesQuery'
import Section from './Section'
import BgImage from './BgImage'
import Button from './Button'

import AppstoreSvg from '../../resources/images/svg/inline/appstore.svg'

const Content = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  padding-bottom: 10rem;

  @media (min-width: 600px) {
    width: 60%;
  }
`

const Ewrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;

  @media (min-width: 1200px) {
    flex-direction: row;
  }
`
const Ecenter = styled.div`
  color: white;

  animation: fade-up 1.2s ease-out;

  @keyframes fade-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
`
const RelativeSection = styled(Section)`
  position: relative;
`
const Shadow = styled.div`
  &::after {
    content: '';
    position: absolute;
    opacity: 0.65;
    width: 100%;
    height: 100%;
    z-index: 0;

    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    );
  }
`

const StyledAppstoreSvg = styled(AppstoreSvg)`
  margin-top: 28px;
  width: 280px;
`

// const DOWNLOAD_URL = 'http://onelink.to/k9rjj4'

const Hero = ({ ecenter1, link, onBtnClickHandler }) => {
  return (
    <ImagesQuery
      render={(images) => (
        <RelativeSection>
          <BgImage
            title='hero'
            fluid={images.heroAmbassadors.childImageSharp.fluid}
            height='100vh'
          >
            <Shadow>
              <Content>
                <Ewrapper>
                  <Ecenter>{ecenter1}</Ecenter>
                </Ewrapper>
                {/* <Link tracking={{ label: 'hero_download' }} href={DOWNLOAD_URL}> */}
                <Button variant='large' onClick={onBtnClickHandler}>{link}</Button>
                <StyledAppstoreSvg />
                {/* </Link> */}
              </Content>
            </Shadow>
          </BgImage>
        </RelativeSection>
      )}
    />
  )
}

Hero.propTypes = {
  ecenter1: PropTypes.node,
  link: PropTypes.string,
  onBtnClickHandler: PropTypes.func
}

export default Hero
